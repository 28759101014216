.cc-input {
  position: relative;
  overflow: hidden;

  label {
    position: absolute;
    color: $color-grey;
    font-size: rem(18);
    line-height: rem(60);
    padding-left: rem(20);
    top: 0;
    transition: all .3s;
    pointer-events: none;
    white-space: nowrap;
  }

  .form-control {
    &:not(textarea){
      height: rem(60);
    }
    border: solid 1px $color-l-grey;
    border-radius: rem(4);
    color: $color-grey;
    line-height: 1.5em;
    padding: rem(23) rem(20) rem(10) rem(20);

    &:focus,
    &.active {
      border-color: $color-grey;
      box-shadow: none;
    }
  }

  select.form-control {
    padding-left: rem(15);
    -webkit-appearance: initial;
    padding-inline-start: 1rem;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSI5NjBweCIgaGVpZ2h0PSI1NjBweCIgdmlld0JveD0iMCAwIDk2MCA1NjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDk2MCA1NjAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgaWQ9IlJvdW5kZWRfUmVjdGFuZ2xlXzMzX2NvcHlfNF8xXyI+DQoJPHBhdGggZD0iTTQ4MCwzNDQuMTgxTDI2OC44NjksMTMxLjg4OWMtMTUuNzU2LTE1Ljg1OS00MS4zLTE1Ljg1OS01Ny4wNTQsMGMtMTUuNzU0LDE1Ljg1Ny0xNS43NTQsNDEuNTcsMCw1Ny40MzFsMjM3LjYzMiwyMzguOTM3DQoJCWM4LjM5NSw4LjQ1MSwxOS41NjIsMTIuMjU0LDMwLjU1MywxMS42OThjMTAuOTkzLDAuNTU2LDIyLjE1OS0zLjI0NywzMC41NTUtMTEuNjk4bDIzNy42MzEtMjM4LjkzNw0KCQljMTUuNzU2LTE1Ljg2LDE1Ljc1Ni00MS41NzEsMC01Ny40MzFzLTQxLjI5OS0xNS44NTktNTcuMDUxLDBMNDgwLDM0NC4xODF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 1rem;
  }

  .form-control:focus ~ label,
  .has-value ~ label {
    top: rem(5);
    left: 0;
    font-size: rem(14);
    cursor: pointer;
    color: $color-muted;
    line-height: 1.5em;
  }
}

.cc-checkbox,
.cc-radio {
  position: relative;
  padding-left: rem(25);
  line-height: rem(18);
  cursor: pointer;

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 0;
  }

  input:checked ~ .cc-radio__checkmark,
  input:checked ~ .cc-checkbox__checkmark {
    background-color: $color-green;
    border-color: $color-green;
  }

  input:not(:checked):disabled ~ .cc-radio__checkmark,
  input:not(:checked):disabled ~ .cc-checkbox__checkmark {
    background-color: $color-l-grey;
    border-color: $color-l-grey;
  }

  input:checked ~ .cc-radio__checkmark:after {
    content: "";
    display: block;
    width: rem(8);
    height: rem(8);
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.2);
  }


  input:checked ~ .cc-checkbox__checkmark:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_dd)'%3E%3Cpath d='M15 2L8.47222 9L5 5.28788' stroke='white' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_dd' x='0.269531' y='0.317993' width='19.4617' height='17.1471' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='3'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='0.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='effect1_dropShadow' result='effect2_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: center rem(2);
    background-repeat: no-repeat;
  }
}

.cc-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: rem(18);
  height: rem(18);
  border: solid 1px $color-muted;
}

.cc-radio__checkmark {
  @extend .cc-checkmark;
  border-radius: 50%;
}

.cc-checkbox__checkmark {
  @extend .cc-checkmark;
  border-radius: rem(4);
}

.has-error {
  .cc-radio__checkmark,
  .cc-checkbox__checkmark,
  input:checked ~ .cc-radio__checkmark,
  input:checked ~ .cc-checkbox__checkmark {
    border-color: $color-danger;
  }
}

.btn-primary {
  color: #ffffff;
  background-color: $color-green;
  border-color: $color-green;
  padding: rem(16) rem(40);

  @media screen and (max-width: 768px) {
    padding: rem(10) rem(25);
  }

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus,
  &:focus {
    background-color: darken($color-green, 10);
    border-color: darken($color-green, 10);
    box-shadow: none;
  }

  &:disabled {
    background-color: $color-muted !important;
    border-color: $color-muted !important;
  }
}

.btn--small {
  padding: rem(5) rem(14);
}

.btn--small {
  padding: rem(5) rem(14);
}

.btn--gray {
  background-color: #999;
  border-color: #999;

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus,
  &:focus {
    background-color: darken(#999, 10);
    border-color: darken(#999, 10);
    box-shadow: none;
  }
}

.cc-btn {
  position: relative;
  transition: opacity .3s;
}

.cc-btn__spinner {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: visibility 0s, opacity .3s;
}

.cc-btn__content {
  opacity: 1;
  transition: opacity .3s;
}

.cc-btn--loading {
  .cc-btn__content {
    opacity: 0;
  }

  .cc-btn__spinner {
    visibility: visible;
    opacity: 1;
  }
}
